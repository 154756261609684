import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Lookup} from 'app/common/lookup';
import {Common} from 'app/common/common';
import {Validate} from 'app/common/validate';
import {AlertService} from '../../../../common/alert.service';
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
    selector: 'app-updatesalary',
    templateUrl: './updatesalary.component.html',
    styleUrls: ['./updatesalary.component.css']
})
export class UpdatesalaryComponent implements OnInit {

    business_id = localStorage.getItem('businessid');
    getLookUp = function () {
        this.lookUp.getLookUp('employees', this.business_id).subscribe((data) => {if (data) {this.EmployeeTypes = data;}});
    };

    SalaryForm: FormGroup;
    id: number;
    amount: string;
    SalaryDetail: object = {};
    EmployeeTypes = [];
    incentives: string;
    total: number;
    salary: string;
    Validate = Validate;

    constructor(private http: HttpClient, private router: Router,
                private route: ActivatedRoute, private lookUp: Lookup,
                private alertService: AlertService) {
    }

    updateSalary = function (salary) {
        if (this.SalaryForm.valid) {
          Common.disabledButton();
            salary.id = this.id;
            salary.total = this.total;
            salary.modified_by = Utility.getLoggedUserId();
          this.http.post(Configuration.serviceCoreURL + "/updatesalary", salary).subscribe((res: Response) => {
              this.router.navigate(['/overheadcosts']);
            },
            (error) => {
              Common.enabledButton();
            }
          );
            // this.http.post(Configuration.serviceCoreURL + "/updatesalary", salary).subscribe((res: Response) => {
            //     this.router.navigate(['/overheadcosts']);
            // });
        } else {
          this.alertService.validationAlert();
        }
    };

    deleteSalary = function (id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.salary = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Configuration.serviceCoreURL + "/deletesalary", this.salary).subscribe((res: Response) => {
                this.router.navigate(['/overheadcosts']);
            });
        }
    };


    calculateAmount(salary) {
        this.salary = salary.salary;
        this.incentives = salary.incentives;
        if (this.incentives === '') {
            this.total = 0;
        } else {
            this.total = parseInt(this.salary) + parseInt(this.incentives);
        }
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');


        this.amount = '^[1-9]\\d*(\\.\\d+)?$';

        this.SalaryForm = new FormGroup({
            employee_id: new FormControl('', Validators.required),
            salary: new FormControl('', Validators.pattern(this.amount)),
            incentives: new FormControl('', Validators.pattern(this.amount)),
            total: new FormControl('', null),
        });

        this.getLookUp();

        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });

        this.http.get(Configuration.serviceCoreURL + "/salarydetails?id=" + this.id).subscribe(data => {
            this.SalaryDetail = data;
            this.total = this.SalaryDetail['total'];
        });

    }

}


