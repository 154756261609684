import {Component, OnInit} from '@angular/core';
import {Http} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Common} from 'app/common/common';
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
    selector: 'app-salaryview',
    templateUrl: './salaryview.component.html',
    styleUrls: ['./salaryview.component.css']
})
export class SalaryviewComponent implements OnInit {

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title) {
    }

    id: number;
    salaryDetail: object = {};
    Common = Common;

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');

        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });

        this.http.get(Configuration.serviceCoreURL + "/salarydetails?id=" + this.id).subscribe(data => {
            this.salaryDetail = data;
        });

        this.title.setTitle('Octanics - Salary');
    }

}


