import {Configuration} from '../../assets/general/configuration';
import {Http} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Utility} from './utility';
import {Roles} from './roles';
@Injectable()
export class RoleService {
  access:any;
  constructor(private http: HttpClient ) {
  }
   getRole(route,role) {
     this.http.get(Configuration.serviceCoreURL + '/getidbyarearoute?route=' + route).subscribe((data) => {
      let areaId = data;
       this.http.get(Configuration.serviceCoreURL + '/getauthorizedarearolepermissions?area_id=' + areaId + '&role=' + role).subscribe(res => {
      if (res['_body'] !== '' && res['_body'] !== null && res['_body'] !== undefined) {
        this.access=res;
        let Write = this.access.find(x => x.name == 'Write');
        if (!Utility.isUndefined(Write)){
          Roles.Write = true;
        }
        let View = this.access.find(x => x.name == 'View');
        if (!Utility.isUndefined(View)){
          Roles.View = true;
        }
        let Import = this.access.find(x => x.name == 'Import');
        if (!Utility.isUndefined(Import)){
          Roles.Import = true;
        }
        let Export = this.access.find(x => x.name == 'Export');
        if (!Utility.isUndefined(Export)){
          Roles.Export = true;
        }
        let Delete = this.access.find(x => x.name == 'Delete');
        if (!Utility.isUndefined(Delete)){
          Roles.Delete = true;
        }
        let WhatsApp = this.access.find(x => x.name == 'WhatsApp');
        if (!Utility.isUndefined(WhatsApp)){
          Roles.WhatsApp = true;
        }
        let SMS = this.access.find(x => x.name == 'SMS');
        if (!Utility.isUndefined(SMS)){
          Roles.SMS = true;
        }
        let Email = this.access.find(x => x.name == 'Email');
        if (!Utility.isUndefined(Email)){
          Roles.Email = true;
        }

      }
    });
     });
  }


}
