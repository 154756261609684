import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from '@app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {Lookup} from '@app/common/lookup';
// @ts-ignore
declare var $: any;
import {Validate} from '@app/common/validate';
import {AlertService} from '../../../../common/alert.service';
import {HttpClient} from "@angular/common/http";
import {Common} from "@app/common/common";

@Component({
    selector: 'app-insertsalary',
    templateUrl: './insertsalary.component.html',
    styleUrls: ['./insertsalary.component.css']
})
export class InsertsalaryComponent implements OnInit {

    constructor(private http: HttpClient, private router: Router,
                private route: ActivatedRoute, private lookUp: Lookup,
                private alertService: AlertService) {
    }
    business_id = localStorage.getItem('businessid');
    getLookUp = function () {
        this.lookUp.getLookUp('employees', this.business_id).subscribe((data) => {if (data) {this.EmployeeTypes = data;}});
    };
    SalaryForm: FormGroup;
    amount: string;
    incentives: string;
    total: number;
    salary: string;
    EmployeeTypes = [];
    Validate = Validate;
    Common=Common;

    insertSalary = function (salary) {
        if (this.SalaryForm.valid) {
          Common.disabledButton();
            salary.total = this.total;
            salary.created_by = Utility.getLoggedUserId();
            salary.modified_by = Utility.getLoggedUserId();
          this.http.post(Configuration.serviceCoreURL + "/insertsalary", salary).subscribe((res: Response) => {
              this.router.navigate(['overheadcosts']);
            },
            (error) => {
              Common.enabledButton();
            }
          );
            // this.http.post(Configuration.serviceCoreURL + "/insertsalary", salary).subscribe((res: Response) => {
            //     this.router.navigate(['overheadcosts']);
            // });
        } else {
          this.alertService.validationAlert();
        }
    };

    calculateAmount(salary) {
        this.salary = salary.salary;
        this.incentives = salary.incentives;
        if (this.incentives === '') {
            this.total = 0;
        } else {
            this.total = parseInt(this.salary) + parseInt(this.incentives);
        }
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');

        this.total = 0;
        this.getLookUp();
        this.amount = '^[1-9]\\d*(\\.\\d+)?$';

        this.SalaryForm = new FormGroup({
            employee_id: new FormControl('', Validators.required),
            salary: new FormControl('', Validators.pattern(this.amount)),
            incentives: new FormControl('', Validators.pattern(this.amount)),
            total: new FormControl('', null),
        });
    }

}
