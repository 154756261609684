export class Roles {
  public  static  Write = false;
  public  static  View = false;
  public  static  Import = false;
  public  static  Export = false;
  public  static  Delete = false;
  public  static  WhatsApp = false;
  public  static  SMS = false;
  public  static  Email = true;
}
