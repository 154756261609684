import {Component, OnInit} from '@angular/core';
import {Http} from "@angular/http";
import {Title} from "@angular/platform-browser";
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Common} from "../../../common/common";
import {ActivatedRoute, Router} from '@angular/router';
import {Sorting} from 'app/common/sorting';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-cancelledserialnumberlist',
  templateUrl: './cancelledserialnumberlist.component.html',
  styleUrls: ['./cancelledserialnumberlist.component.css']
})
export class CancelledserialnumberlistComponent implements OnInit {


  constructor(private http: HttpClient, private title: Title, private router: Router, private route: ActivatedRoute) {
  }

  Sorting = Sorting;
  cancelledserialnumbers:object={};
  searchText;
  p: Number = 1;
  Common = Common;
  count: Number = 20;
  type: string;
  order: boolean = true;
  property: string = null;

  sorting(property, obj) {
        if (property == this.property) {
            this.property = property;
            this.order = !this.order;
            if (this.order) {
                $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
            } else {
                $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            }
            Sorting.sort(property, obj, this.order);
        } else {
            this.property = property;
            this.order = false;
            $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
            $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            Sorting.sort(property, obj, this.order);
        }
    }

  getcancelledserialnumberList () {
    this.http.get(Configuration.serviceCoreURL + "/cancelledserialnumbers?type=" + this.type).subscribe(data => {
      this.cancelledserialnumbers = data;
    });
  };

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
    });
    this.getcancelledserialnumberList();
    this.title.setTitle('Octanics - Cancelled Serial Number');
  }
}
