import {Component, OnInit} from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Lookup} from "../../../common/lookup";
import {Common} from "../../../common/common";
import {AlertService} from "../../../common/alert.service";
import {Validate} from '../../../common/validate';
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'app-updatecancelledserialnumber',
  templateUrl: './updatecancelledserialnumber.component.html',
  styleUrls: ['./updatecancelledserialnumber.component.css']
})
export class UpdatecancelledserialnumberComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute,
              private fb: FormBuilder, private lookUp: Lookup, private alertService: AlertService) {
  }

  id: number;
  year = [];
  months = [];
  cancelledserialnumber: object = {};
  cancelledSerialNumberForm: FormGroup;
  cancelledserialnumbersdetails: object = {};
  month_id: any;
  year_id: any;
  type: string;
  Validate = Validate;

  getLookUp() {
    this.lookUp.getLookUp('year').subscribe((data) => {
      if (data) {
        this.year = data;
      }
    });
    this.lookUp.getLookUp('months').subscribe((data) => {
      if (data) {
        this.months = data;
      }
    });
  }

  updateCancelledSerialNumber(cancelledSerialNumber) {
    if (this.cancelledSerialNumberForm.valid) {
      cancelledSerialNumber.id = this.id;
      cancelledSerialNumber.modified_by = Utility.getLoggedUserId();
      this.http.post(Configuration.serviceCoreURL + '/updatecancelledserialnumber', cancelledSerialNumber).subscribe((res: Response) => {
        this.router.navigate(['/cancelledserialnumber'], {queryParams: {type: this.type}});
      });
    } else {
      this.alertService.validationAlert();
    }
  }

  deleteCancelledSerialNumber = function (id) {
    if (confirm("Are you sure you want to delete this record?")) {
      this.brand = {
        'id': id,
        'deleted_by': Utility.getLoggedUserId(),
      };
      this.http.post(Configuration.serviceCoreURL + '/deletecancelledserialnumber', this.brand).subscribe((res: Response) => {
        this.router.navigate(['/cancelledserialnumber'], {queryParams: {type: this.type}});
      });
    }
  };

  ngOnInit() {
    this.getLookUp();
    this.cancelledSerialNumberForm = new FormGroup({
      serialnumber: new FormControl('', Validators.required),
      month_id: new FormControl('', Validators.required),
      year_id: new FormControl('', Validators.required),
    });

    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });

    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
    });

    this.http.get(Configuration.serviceCoreURL + "/cancelledserialnumberdetails?id=" + this.id).subscribe(data => {
      this.cancelledserialnumbersdetails = data;
    });
  }

}
